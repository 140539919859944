@charset "utf-8";

// Set brand colors
$primary: #43b2bf;
$info: #444950;
$success: #3db188;
$warning: #f49022;
$danger: #3d66b1;

// Update Bulma's global variables
$family-sans-serif: "Roboto", sans-serif;

// Update some of Bulma's component variables
$navbar-height: 4.5rem;
$navbar-item-img-max-height: 4rem;
$navbar-padding-vertical: 0.5rem;
$navbar-padding-horizontal: 2rem;
$footer-padding: 3rem 1.5rem 2rem;

//import bulma
@import "bulma/bulma-0.8.0/bulma.sass";

.navbar {
    background-image: linear-gradient(to bottom right, $primary, $turquoise);
}

.navbar-end {
    font-size: 1.25em;
    font-weight: 600;
}

.navbar-item-text {
    margin-left: .25rem;
    color: #303536;
}

.navbar-item-icon {
    color: #303536;
}

.navbar-item {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.social-icons {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.social-icons .icon {
    margin-left: 1rem;
    margin-right: 1rem;
}

.footer-links {
    color: white;
}

.fa-youtube {
    color: #c4302b;
    background-image: radial-gradient(at center, white 40%, transparent 40%);
}

.fa-twitter {
    color: #00aced;
}

svg.fa-instagram * {
    fill: url(#instagramrg);
}

.fa-envelope {
    color: $white-ter;
}

#hero-title {
    margin-bottom: 2rem;
    font-size: 3.5rem;
    color: #082328;
}

.hero-vid {
    margin-top: 3rem;
    width: 50%;
}

.weteach {
    padding-top: 3rem;
    padding-bottom: 6rem;
}

.recentvideos {
    padding-top: 3rem;
    padding-bottom: 6rem;
}

.course-container {
    width: 75%;
}

.course-card {
    height: 15rem;
    border-radius: 1rem;
    background-image: linear-gradient(to bottom right, $danger, $primary);
    align-items: center;
    display: flex;
}

.course-card p {
    margin: auto;
    font-size: 1.6rem;
}

#ideas.course-card {
    background-image: linear-gradient(to bottom right, $danger, #6085C9);
}

#research.course-card {
    background-image: linear-gradient(to bottom right, #843FAA, #954CBD);
}

#earnings.course-card {
    background-image: linear-gradient(to bottom right, #E74236, #EA5348);
}

#modeling.course-card {
    background-image: linear-gradient(to bottom right, #F57A00, #FF8F1F);
}

#portfolio.course-card {
    background-image: linear-gradient(to bottom right, #FEC620, #FECB34);
}

#shortselling.course-card {
    background-image: linear-gradient(to bottom right, #38A878, #60CA9D);
}

.carousel-container {
    margin-top: 2rem;
    width: 75%;
}

.short-description {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: linear-gradient(to bottom right, $primary, $turquoise);
}

#description-title {
    color: white;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

#description-subtitle {
    color: white;
    margin-bottom: 3rem;
    font-size: 1.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.image-grid {
    width: 75%;
}

#hero-about {
    padding-bottom: 4rem;
    padding-top: 4rem;
    
    @include until($tablet) {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }
}

.stockgeekstory {
    width: 75%;
    
    @include until($tablet) {
        width: 100%;
    }
}

.more-about-me {
    width: 75%;
    
    @include until($tablet) {
        width: 100%;
    }
}

#professional-stuff-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#fun-stuff-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

ul.list-about-me {
    list-style-type: disc;
    list-style-position: inside;
}

.professional-stuff li {
    padding-bottom: 1rem;
}

.fun-stuff li {
    padding-bottom: 1rem;
}

#container-mycourses {
    width: 85%;
}

.course-info {
    height: 30rem;
    border-radius: 1rem;
    //background-image: linear-gradient(#5853aa, #43b2bf);
    align-items: center;
    display: flex;
}

.course-info p {
    margin: auto;
}

#course1.course-info {
    background-image: linear-gradient(#5853aa, #43b2bf);
}

#course2.course-info {
    background-image: linear-gradient(#dba145, #833ab4);
}

#course3.course-info {
    background-image: linear-gradient(#de6161, #2657eb);
}

.course-title {
    font-size: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.courses-hero-body {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    //background-image: linear-gradient(to bottom right, $info, $primary);
}

#paddedlogo {
    padding-bottom: 2rem;
}

#hero-bootcamp-with-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/assets/images/trav_teaching.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //position: relative;
    
    padding-top: 10rem;
    padding-bottom: 10rem;
    
    @include until($tablet) {
        padding-bottom: 6rem;
        padding-top: 6rem;
    }
}

#bootcamp-hero-subtitle {
    margin-bottom: 2rem;
    font-size: 1.4rem;
}

#learn-subtitle {
    font-size: 2rem;
}

ul.who-for-list {
    list-style-type: disc;
    list-style-position: inside;
}

#course-overview {
    padding-top: 1rem;
}

#teacher {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: linear-gradient(to bottom right, $primary, $turquoise);
}

.what-you-will-learn {
    width: 100%;
    
    @include until($tablet) {
        width: 100%;
    }
}

#meet-instructor {
    width: 75%;
    
    @include until($tablet) {
        width: 90%;
    }
}

#meet-instructor-title {
    padding-bottom: 1.5rem;
}

#pricing-title {
    padding-bottom: 0.75rem;
}

#pricing-container {
    width: 75%;
    
    @include until($tablet) {
        width: 90%;
    }
}

#self-paced-box {
    border-top: 8px solid $turquoise;
    //border-bottom: 5px solid $turquoise;
}

#live-course-box {
    border-top: 8px solid $warning;
    //border-bottom: 5px solid $turquoise;
}

.price {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

ul.included-list {
    list-style-type: disc;
    list-style-position: inside;
}

.included-list li {
    margin-bottom: 0.35rem;
}

.course-purchase-cta {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#sign-up-button-recorded-course {
    background-color: $turquoise;
}

#course-sample {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: linear-gradient(to bottom right, $primary, $turquoise);
}

#course-faq {
    padding-top: 4rem;
    padding-bottom: 4rem;
    
    @include until($tablet) {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }
}

#faq-container {
    width: 75%;
    
    @include until($tablet) {
        width: 90%;
    }
}

.message:not(:last-child) {
    margin-bottom: 0.1rem;
}

article.accordion {
    margin-bottom: 0.1rem;
}

.accordion-header {
    cursor: pointer;
}

.accordion-body {
    display: none;
}